<template>
  <div id="app">
    <Nav :checks="checks" id="navhi" />
    <Home />
    <Footer />
  </div>
</template>

<script>
import Nav from "./components/topnav.vue";
import Footer from "./components/footer.vue";
import Home from './views/Home.vue'

export default {
  name: 'App',
  components: {
    Home,
    Nav,
    Footer
  },
  data() {
    return {
      property: "value",
      checks: 0,
    };
  },
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
</style>

