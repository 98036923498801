<template>
  <!-- 头部导航 -->
  <div class="topnav">
    <div style="display: flex;align-items: center;">
      <img style="height: .5rem;margin-right: 10px;" src="https://zmt-source.maolinbaoxian.com/upload/website/logo-xyz.png" alt="" />
      <div class="compny">北京富邦保险代理有限公司</div>
    </div>
    <div class="titlewrap">
      <div
        class="titleitem"
        :style="checks == index ? 'color: #da251d;font-weight: 550' : ''"
        v-for="(item, index) in navtitle"
        :key="item.id"
      >
        {{ item.name }}
        <div
          class="bott"
          :style="checks == index ? 'opacity: 1' : 'opacity: 0'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checks: Number,
  },
  data() {
    return {
      navtitle: [
        {
          name: "公司简介",
          id: "345klj",
        },
        {
          name: "产品",
          id: "799kjhi",
        },
        {
          name: "合作大V",
          id: "314jdh",
        },
        {
          name: "联系我们",
          id: "575jck",
        },
      ],
    };
  },
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>
.topnav {
  @include wihe(calc(100vw - 3.28rem));
  @include dif(space-between, center);
  position: fixed;
  top: 0;
  left: 0;
  padding:0 1.6rem;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0 6px rgb(223, 223, 223);
  img {
    display: block;
    // width: 2.15rem;
    height: 0.8rem;
  }
  .compny {
    font-size: 0.4rem;
    font-weight: bold;
    color: #333;
  }
}
.titlewrap {
  @include dif(space-between);
  .titleitem {
    font-size: 0.16rem;
    padding: 0.35rem 0.1rem;
    color: #666;
    position: relative;
    margin-left: 0.8rem;
    transition: color 0.2s linear;
    white-space: nowrap;
    .bott {
      position: absolute;
      bottom: 0;
      left: 0;
      @include wihe(100%, 0.03rem, 0.02rem);
      background-color: #da251d;
      transition: all 0.3s linear;
    }
    &:hover {
      color: #da251d;
      cursor: pointer;
    }
  }
}
</style>