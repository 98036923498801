<template>
  <!-- 底部 -->
  <div class="footer">
    <div class="gotop">
      <div class="lefts">
        <!-- <img src="https://zmt-source.maolinbaoxian.com/upload/website/logo.png" alt="" /> -->
        <div class="compny">北京富邦保险代理有限公司</div>
        <span
          >我们的使命：让天下没有难卖的保险，让天下没有难买的保险。</span
        >
      </div>
      <img class="right" src="https://zmt-source.maolinbaoxian.com/upload/website/top.png" alt="" @click="gotop" />
    </div>
    <div class="footmsg">
      <div class="footwrap">
        <div class="item">
          <div class="title">产品服务</div>
          <div class="bott df">
            <ul class="left">
              <li v-for="item in proser" :key="item.id">
                {{ item.name }}
              </li>
            </ul>
            <ul class="left right">
              <li v-for="item in prosers" :key="item.id">{{ item.name }}</li>
            </ul>
          </div>
        </div>
        <div class="item" v-for="item in footerdata" :key="item.name">
          <div class="title">{{ item.name }}</div>
          <ul class="left bott">
            <li v-for="ite in item.arr" :key="ite.id">{{ ite.name }}</li>
          </ul>
        </div>
        <div class="imgs">
          <img src="https://zmt-source.maolinbaoxian.com/upload/website/wx.png" alt="" />
        </div>
      </div>
      <div class="footbtn">
        <span
          >版权所有©北京富邦保险代理有限公司保留一切权利<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="margin-left: 20px;color:#fff;text-decoration: none;">京ICP备18046141号-1</a></span
        >
        <div class="end">
          <span>使用条款</span>
          <span>隐私政策</span>
          <span>法律信息</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      proser: [
        {
          name: "意外险",
          id: "12uij",
        },
        {
          name: "人寿险",
          id: "12lkj",
        },
        {
          name: "健康险",
          id: "12ujh",
        },
      ],
      prosers: [
        {
          name: "车险",
          id: "89ius",
        },
        {
          name: "家财险",
          id: "12osh",
        },
        {
          name: "宠物险",
          id: "12ugc",
          link: "/product",
        },
      ],
      footerdata: [
        {
          name: "合作大V",
          arr: [
            {
              name: "马洪涛",
              id: "67khui",
            },
            {
              name: "陈先生",
              id: "12khui",
            },
            {
              name: "房岩小哥",
              id: "22khui",
            },
            {
              name: "花姐——歌手",
              id: "24khui",
            },
          ],
        },
        {
          name: "合作公司",
          arr: [
            {
              name: "前海财险",
              id: "67idjs",
            },
            {
              name: "阳光保险",
              id: "67oich",
            },
            {
              name: "中国人民保险",
              id: "67qodj",
            },
            {
              name: "中国平安",
              id: "67cpus",
            },
          ],
        },
        {
          name: "了解我们",
          arr: [
            {
              name: "企业简介",
              id: "67pcys",
            },
            {
              name: "企业文化",
              id: "67rwtd",
            },
            {
              name: "企业资质",
              id: "67pctw",
            },
            {
              name: "企业荣誉",
              id: "67xius",
            },
          ],
        },
        {
          name: "加入我们",
          arr: [
            {
              name: "联系我们",
              id: "67ctel",
            },
            {
              name: "人才招聘",
              id: "67uyte",
            },
          ],
        },
      ],
    };
  },
  methods: {
    gotop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.gotop {
  @include dif(space-between, center);
  padding: 0.66rem 1.6rem 0.56rem 1.68rem;
  background-color: #f0edf1;
  .lefts {
    width: 50%;
    text-align: justify;
    font-size: 0.14rem;
    color: #333;
    letter-spacing: 0.01rem;
    img {
      display: block;
      width: 2rem;
      margin-bottom: 0.21rem;
    }
    span {
      font-size: 0.18rem;
      line-height: 0.35rem;
    }
  }
  .right {
    width: 0.9rem;
    display: block;
  }
}
.footmsg {
  padding: 0 1.6rem;
  background-color: #01405b;
  .footwrap {
    @include dif(space-between);
    color: #fff;
    padding: 0.5rem 0.1rem 0.22rem 0rem;
    border-bottom: 1px solid #fff;
    .imgs {
      display: flex;
      align-self: flex-end;
      margin-left: 0.5rem;
      img {
        display: block;
        width: 0.22rem;
        margin-left: 0.1rem;
      }
    }
  }
  .footbtn {
    padding: 0.3rem 0;
    font-size: 0.16rem;
    opacity: 0.9;
    font-weight: lighter;
    color: #f7faff;
    @include dif(space-between);
    .end {
      span {
        margin-left: 0.3rem;
      }
    }
  }
}
.title {
  font-size: 0.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.47rem;
}
.bott {
  font-size: 0.16rem;
  font-weight: lighter;
}
.left {
  margin-bottom: 1rem;
  list-style: none;
  li {
    margin-bottom: 0.12rem;
    cursor: pointer;
  }
}
.right {
  margin-left: 0.5rem;
  cursor: pointer;
}
.compny {
  font-size: .3rem;
  font-weight: bold;
  color: #da251d;
}

a:hover {
  color: #b3c6ff !important;
}
</style>